import { SUPPORT_EMAIL_ADDRESS } from '@constants/contactUs';
import { Link, SxProps } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  sx?: SxProps;
  children?: ReactNode;
};

export function EmailUsLink({ sx = {}, children }: Props) {
  return (
    <Link href={`mailto:${SUPPORT_EMAIL_ADDRESS}`} sx={sx}>
      {children || SUPPORT_EMAIL_ADDRESS}
    </Link>
  );
}
